<template>
  <div class="login-form">
    <!--    <Preloader />-->
    <form class="form-signin" @submit.prevent="login">
      <div class="form-group">
        <div v-if="errors.email">{{ errors.email[0] }}</div>
        <label for="inputEmail" class="sr-only">Email address</label>
        <input
          v-model="formData.email"
          type="text"
          class="form-control"
          placeholder="Email address"
          autofocus
        />
      </div>
      <div class="form-group">
        <div v-if="errors.password">{{ errors.password[0] }}</div>
        <label for="inputPassword" class="sr-only">Password</label>
        <input
          v-model="formData.password"
          type="password"
          class="form-control"
          placeholder="Password"
        />
      </div>
      <button
        class="btn btn-lg btn-primary btn-block btn-login"
        type="submit"
        :disabled="spinner_block"
      >
        Войти
        <span v-if="spinner_block" class="spinner"></span>
      </button>
    </form>
  </div>
</template>

<script>
import Preloader from "../../components/Preloader";
import { mapMutations } from "vuex";
import jwtServices from "@/services/jwt.service";

export default {
  name: "Login",
  data() {
    return {
      spinner_block: false,
      formData: {},
      errors: {},
      isProcess: false,
    };
  },
  methods: {
    ...mapMutations(["updateIsLoading"]),
    login() {
      this.spinner = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/sanctum/csrf-cookie`)
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API_URL}/login`, this.formData)
            .then((res) => {
              if (res.data.message === "authenticated") {
                this.spinner = false;
                jwtServices.saveToken(res.data.message);
                document.location.href = "/";
              }
            })
            .catch((error) => {
              this.spinner = false;
              this.errors = error.response.data.errors;
            });
        });
    },
  },
  mounted() {
    // this.$store.commit('updateIsLoading', false)
  },
  components: {
    Preloader,
  },
};
</script>

<style>
html,
body {
  height: 100%;
}

.login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 40px;
  background-color: #eef0f8;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
}
.spinner {
  position: absolute;
  right: 40px;
  top: 20px;
}
.btn-login {
  position: relative;
}
</style>
